import { useContext, useEffect } from "react";
import { PageTitleContext } from "../../PageTitleContext";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useAuth } from "../../AuthContext";

const Profile = () => {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	const { user, logout } = useAuth();

	useEffect(() => {
		setTitle("Profile von " + user.username);
	}, [setTitle]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100vh",
				borderRadius: "8px",
				boxShadow: 3,
				padding: "20px",
				position: "relative",
			}}
		>
			<Box
				component="img"
				src={"http://" + user.photo_path} // Укажите путь к изображению по умолчанию
				alt="Profile"
				sx={{
					width: "150px",
					height: "150px",
					borderRadius: "50%",
					marginBottom: "20px",
					objectFit: "cover",
					border: "3px solid white", // Белая рамка для выделения
				}}
			/>

			{/* Имя пользователя */}
			<Typography variant="h4" color="white">
				{user.username}
			</Typography>

			<Button
				variant="contained"
				color="secondary"
				sx={{
					borderRadius: 4,
					paddingX: 3,
					marginLeft: 2,
					marginTop: 4,
				}}
				onClick={logout}
			>
				Ausloggen
			</Button>
		</Box>
	);
};

export default Profile;
