import React, { useState } from "react";
import {
	TextField,
	Button,
	Box,
	Typography,
	Paper,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { useAuth } from "../../AuthContext";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginMain = () => {
	const [username, setUsername] = useState("worker");
	const [password, setPassword] = useState("worker");
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { login } = useAuth();

	const errorMessages = {
		"Invalid username or password.": "Ungültiger Benutzername oder Passwort.",
		"Access denied. Insufficient permissions.":
			"Zugriff verweigert. Unzureichende Berechtigungen.",
		"Failed to fetch": "Etwas ist schief gelaufen.",
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setErrorMessage(""); // Сброс ошибки перед отправкой

		try {
			await login(username, password); // Вход с обработкой
		} catch (error) {
			const translatedMessage =
				errorMessages[error.message] ||
				"Ein unbekannter Fehler ist aufgetreten."; // Сообщение по умолчанию
			setErrorMessage(translatedMessage);
		}
	};

	const toggleShowPassword = () => {
		setShowPassword(prev => !prev);
	};

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="80vh"
			bgcolor="background.default"
			padding="10px"
		>
			<Paper
				elevation={3}
				style={{ padding: "20px", maxWidth: "400px", width: "100%" }}
			>
				<Typography variant="h5" textAlign="center" marginBottom={2}>
					Login
				</Typography>
				<form onSubmit={handleSubmit}>
					<TextField
						fullWidth
						label="Benutzername" // Текст для поля username на немецком
						variant="outlined"
						margin="normal"
						value={username}
						onChange={e => setUsername(e.target.value)}
					/>
					<TextField
						fullWidth
						label="Passwort" // Текст для поля password на немецком
						type={showPassword ? "text" : "password"}
						variant="outlined"
						margin="normal"
						value={password}
						onChange={e => setPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={toggleShowPassword}
										onMouseDown={event => event.preventDefault()}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					{errorMessage && (
						<Typography
							color="error"
							variant="body2"
							textAlign="center"
							marginTop={1}
						>
							{errorMessage}
						</Typography>
					)}

					<Button
						fullWidth
						variant="contained"
						color="success"
						type="submit"
						sx={{ borderRadius: 4, paddingX: 3, marginTop: 2 }}
					>
						Anmelden
					</Button>
				</form>
			</Paper>
		</Box>
	);
};

export default LoginMain;
