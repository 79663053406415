import {
	Box,
	Button,
	IconButton,
	Typography,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { useContext } from "react";
import { PageTitleContext } from "../../PageTitleContext";
import PersonIcon from "@mui/icons-material/Person"; // Иконка пользователя
import MenuIcon from "@mui/icons-material/Menu"; // Иконка меню
import ProfilImg from "../../img/profile-img.webp";
import { useAuth } from "../../AuthContext";
import { Link } from "react-router-dom";

const Topbar = ({ isCollapsed, setIsCollapsed }) => {
	const theme = useTheme();
	const { title } = useContext(PageTitleContext);
	const { logout, isAuthenticated, loadingLogin, user } = useAuth();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Проверка для мобильных

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			p={2}
		>
			{/* Левая часть — название страницы или бургер-меню для мобильных */}
			<Box display="flex" alignItems="center">
				{isMobile && (
					<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
						<MenuIcon />
					</IconButton>
				)}
				{loadingLogin ? (
					""
				) : isAuthenticated ? (
					<Typography variant={isMobile ? "h6" : "h4"} sx={{ m: "10px 15px" }}>
						{title}
					</Typography>
				) : (
					""
				)}
			</Box>

			{/* Правая часть — иконки и элементы авторизации */}
			<Box display="flex" alignItems="center">
				{/* Иконка профиля для мобильных */}

				{/* Вход/выход из аккаунта */}
				{loadingLogin ? (
					""
				) : isAuthenticated ? (
					<>
						<Link to="/">
							<Box
								width="40px"
								height="40px"
								sx={{ display: isMobile ? "block" : "none" }}
							>
								<img
									width="40px"
									height="40px"
									src={
										user.photo_path ? `http://${user.photo_path}` : ProfilImg
									}
									alt="Profile"
									style={{ borderRadius: "50%", objectFit: "cover" }}
								/>
							</Box>
						</Link>

						<Box display="flex" alignItems="center">
							{/* Имя пользователя и аватар */}
							<Typography
								variant="h5"
								sx={{
									marginRight: 2,
									marginLeft: 4,
									color: theme.palette.text.primary,
									display: isMobile ? "none" : "block",
								}}
							>
								{user.username}
							</Typography>
							<Box
								width="40px"
								height="40px"
								sx={{ display: isMobile ? "none" : "block" }}
							>
								<img
									width="40px"
									height="40px"
									src={
										user.photo_path ? `http://${user.photo_path}` : ProfilImg
									}
									alt="Profile"
									style={{ borderRadius: "50%", objectFit: "cover" }}
								/>
							</Box>

							<Button
								variant="contained"
								color="secondary"
								sx={{
									borderRadius: 4,
									paddingX: 3,
									marginLeft: 2,
									display: isMobile ? "none" : "block",
								}}
								onClick={logout}
							>
								Ausloggen
							</Button>
						</Box>
					</>
				) : (
					<Button
						variant="contained"
						color="success"
						sx={{
							marginLeft: 2,
							borderRadius: 4,
							paddingX: 3,
						}}
					>
						<Link to="/">Anmelden</Link>
					</Button>
				)}
			</Box>
		</Box>
	);
};

export default Topbar;
