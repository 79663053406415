import { Box, Tooltip } from "@mui/material";

// Компонент для отображения индикатора статуса
const StatusIndicator = ({ status, takenDate, returnedDate }) => {
	const indicatorColor = status === 1 ? "green" : status === 0 ? "red" : "gray"; // Цвет индикатора
	let displayDate = "";
	let fullDate = ""; // Для полного формата даты

	// Определяем, какую дату отображать
	if (status === 0) {
		displayDate = new Date(takenDate).toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit",
		}); // Время взятия
		fullDate = new Date(takenDate).toLocaleString(); // Полная дата для Tooltip
	} else if (status === 1 && returnedDate) {
		displayDate = new Date(returnedDate).toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit",
		}); // Время возврата
		fullDate = new Date(returnedDate).toLocaleString(); // Полная дата для Tooltip
	} else if (status === 3) {
		displayDate = "-"; // Для нового ключа
	}

	return (
		<Box display="flex" alignItems="center">
			<Tooltip arrow placement="top" title={fullDate || "-"}>
				<Box display="flex" alignItems="center" style={{ cursor: "pointer" }}>
					<Box
						sx={{
							width: 12,
							height: 12,
							borderRadius: "50%",
							backgroundColor: indicatorColor,
							marginRight: 1,
							cursor: "pointer", // Указатель при наведении на кружок
						}}
					/>
					<span>{displayDate || "-"}</span> {/* Отображаем дату или '-' */}
				</Box>
			</Tooltip>
		</Box>
	);
};

export default StatusIndicator;
