import { useContext, useEffect, useState } from "react";
import { PageTitleContext } from "../../PageTitleContext";
import {
	Box,
	Typography,
	Button,
	Card,
	CardContent,
	Grid,
	Select,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import StatusIndicator from "../../components/UI/StatusIndicator"; // Импортируем компонент StatusIndicator
import { Html5QrcodeScanner } from "html5-qrcode";
const Schlussel = () => {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	const [takenKeys, setTakenKeys] = useState([]); // Хранение списка взятых ключей
	const [returnedKeys, setReturnedKeys] = useState([]); // Хранение списка отданных ключей

	const [startScan, setStartScan] = useState(false);

	const handleScan = result => {
		if (result) {
			console.log("Отсканированные данные:", result);
			setStartScan(false);
		}
	};

	const handleError = error => {
		console.error("Ошибка сканирования:", error);
	};

	useEffect(() => {
		if (startScan) {
			const qrScanner = new Html5QrcodeScanner(
				"qr-reader",
				{ fps: 10, qrbox: 250 },
				false
			);
			qrScanner.render(handleScan, handleError);

			return () => qrScanner.clear();
		}
	}, [startScan]);

	useEffect(() => {
		setTitle("Schlüssel verwalten");
		// Загрузка списка ключей
		fetchTakenKeys();
		fetchReturnedKeys();
	}, [setTitle]);

	const fetchTakenKeys = async () => {
		const fetchedTakenKeys = [
			{ id: 1, name: "Office Key", status: 0, takenAt: "2023-11-05" },
			{ id: 2, name: "Lab Key", status: 0, takenAt: "2023-11-03" },
		];
		setTakenKeys(fetchedTakenKeys);
	};

	const fetchReturnedKeys = async () => {
		const fetchedReturnedKeys = [
			{ id: 3, name: "Storage Room Key", status: 1, returnedAt: "2023-11-02" },
			{ id: 4, name: "Front Door Key", status: 1, returnedAt: "2023-11-01" },
		];
		setReturnedKeys(fetchedReturnedKeys);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "100vh",
				borderRadius: 2,
				boxShadow: 3,
				p: 3,
				position: "relative",
			}}
		>
			<Typography variant="h4" gutterBottom>
				Управление ключами
			</Typography>

			{/* Взятые ключи */}
			<Typography variant="h5" gutterBottom>
				Взятые ключи
			</Typography>
			<Grid container spacing={2}>
				{takenKeys.map(key => (
					<Grid item xs={12} sm={6} md={4} key={key.id}>
						<Card>
							<CardContent>
								<Typography variant="h6">{key.name}</Typography>
								<StatusIndicator status={key.status} takenDate={key.takenAt} />
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>

			{/* Отданные ключи за последние 7 дней */}
			<Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
				Отданные ключи (последние 7 дней)
			</Typography>
			<Grid container spacing={2}>
				{returnedKeys.map(key => (
					<Grid item xs={12} sm={6} md={4} key={key.id}>
						<Card>
							<CardContent>
								<Typography variant="h6">{key.name}</Typography>
								<StatusIndicator
									status={key.status}
									returnedDate={key.returnedAt}
								/>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>

			{/* Кнопка сканирования QR-кода */}
			<Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
				<Button
					variant="contained"
					color="primary"
					startIcon={<QrCodeScannerIcon />}
					onClick={() => setStartScan(prev => !prev)}
				>
					{startScan ? "Остановить сканирование" : "Начать сканирование"}
				</Button>
				<Button
					variant="contained"
					color="primary"
					startIcon={<QrCodeScannerIcon />}
					onClick={() => setStartScan(prev => !prev)}
				>
					{startScan ? "Остановить сканирование" : "Начать сканирование"}
				</Button>

				{startScan && <div id="qr-reader" style={{ width: "300px" }} />}
			</Box>
		</Box>
	);
};

export default Schlussel;
